.wrapper {
  line-height: 1.8;
}

.h1 {
  font-size: 2rem;
}

.h2 {
  font-size: 1.5rem;
}

.p {
  margin-bottom: 1rem;
}

.em {
  font-style: italic;
}

.strong {
  font-weight: bold;
}

.a {
  color: blue;
}