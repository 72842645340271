* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  background: none;
  text-decoration: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: inherit;
}

*::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 300;
  font-kerning: normal;
  font-size: 20px;

  line-height: 1.4;
  word-wrap: break-word;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  background-color: #eee;
  color: rgba(0, 0, 0, 0.8);
}

:global #app {
  flex: 1 0;
  display: flex;
  flex-direction: column;
}