.Footer {
  color: white;
  background-color: #444;
  line-height: 1.5;
  padding: 3rem 0;
}

.Footer a {
  color: #ff9291;
  font-weight: 400;
}

.FooterMain {
  display: flex;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: var(--site-width);
}

.FooterNav {
  flex: 0 0 auto;
  display: flex;
}

.NavItem {
  padding: 1rem;
}

.FooterAbout {
  flex: 1 0 auto;
}